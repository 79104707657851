import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import IntroductionSection from '../components/IntroductionSection'
import './HomePage.css'

// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, subtitle, featuredImage, abstract, news, next_annual_meeting, next_annual_meeting_information_file, introductions}) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />

    <section className="section Home--Section1">
      <div className="container Home--Section1--Container">
        <div>
			<h2>九州史学会とは</h2>
			<Content source={abstract} />
        </div>
        <div>
			<div className="News--List">
				<h2>お知らせ</h2>
				<Content source={news} />
			</div>
			<div className="clearfix">
				<h2>最新の大会情報</h2>
				<Content source={next_annual_meeting} />
				{next_annual_meeting_information_file &&
				<a href={`${next_annual_meeting_information_file}/-/inline/yes/`} className="Button Button--AnnualMeeting--Program">
						案内
				</a>
				}
			</div>
        </div>
      </div>
	</section>
    {!!introductions.length && (
      <section className="section">
        <div className="container">
 	  	<IntroductionSection posts={introductions} />
        </div>
      </section>
    )}
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page, introductions } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate
		{...page}
		{...page.frontmatter}
		body={page.html}
        introductions={introductions.edges.map(introduction=> ({
          ...introduction.node,
          ...introduction.node.frontmatter
        }))}
	/>
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
		abstract
		news
		next_annual_meeting
		next_annual_meeting_information_file
      }
    }

	introductions: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "introduction" } } }
      sort: { order: ASC, fields: [frontmatter___display_number] }
    ) {
      edges {
        node {
          frontmatter {
			name
			image
			introduction
			url
          }
        }
      }
    }
  }
`
