import React from 'react'
import IntroductionCard from './IntroductionCard.js'
import './IntroductionSection.css'

class IntroductionSection extends React.Component{
  static defaultProps = {
    posts: []
  }

  render() {
    const { posts } = this.props

    return (
      <div className="IntroductionSection">
        {!!posts.length && (
          <div className="IntroductionSection--Grid">
            {posts.map((post, index) => (
              <IntroductionCard key={post.name + index} {...post} />
            ))}
          </div>
        )}
	  </div>
    )
  }
}
export default IntroductionSection
