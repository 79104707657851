import React from 'react'
import Image from './Image'
import './IntroductionCard.css'

const IntroductionCard = ({
  name,
  image,
  introduction,
  url
}) => (
  <a href={url} className="IntroductionCard">
    {image && (
      <div className="IntroductionCard--Image relative">
        <Image background src={image} alt={name} />
      </div>
    )}
    <div className="IntroductionCard--Content">
      {name && <h3 className="IntroductionCard--Title">{name}</h3>}
      {introduction && <div className="IntroductionCard--Excerpt">{introduction}</div>}
    </div>
  </a>
)

export default IntroductionCard
